import React, { useEffect, useState } from 'react';
import { Alert, Breadcrumbs, Button, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { useFormik } from '../../../node_modules/formik/dist/index';
import { Link, useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import axios from '../../../node_modules/axios/index';
import { styled } from '@mui/material/styles';
import { Avatar, Dialog, DialogContent, InputAdornment, Switch } from '../../../node_modules/@mui/material/index';
import { LoadingButton } from '../../../node_modules/@mui/lab/index';
import SaveIcon from '@mui/icons-material/Save';

const ColorButton = styled(Button)(() => ({
  color: '#000000',
  backgroundColor: 'gray',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#ff0000',
    borderRadius: '8px'
  }
}));

const BackButton = styled(Button)(() => ({
  color: '#000000',
  backgroundColor: 'silver',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'gray',
    borderRadius: '8px'
  }
}));

const IsuzuSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#FF0000' : '#FF0000',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const AddUser = () => {
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const id = parseInt(location.pathname.split('/')[3]);
  const [userDetails, setUserDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
    setSuccessOpen(false);
    // window.location.reload(false);
  };

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }

    const config1 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/users/${id}`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };

    try {
      axios.request(config1).then(function (response) {
        const data = response;
        console.log('res', data.data);
        setUserDetails(data.data);
        setFile(data.data.profileImage);
      });
    } catch (err) {
      console.log('err->', err);
    }

    let config2 = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/roles`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };

    axios
      .request(config2)
      .then((response) => {
        console.log(response.data.data);
        setRoles(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [accessToken, id, navigate]);

  console.log('userdetails', userDetails);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: userDetails ? userDetails.fullName : '',
      email: userDetails ? userDetails.email : '',
      contactNumber: userDetails ? userDetails.phoneNumber : '',
      // password: password ? password : '',
      roleId: '',
      active: userDetails ? userDetails.active : ''
    },

    onSubmit: async (values) => {
      setButtonDisabled(true);
      const { fullName, contactNumber, active } = values;
      if (selectedFile) {
        const formData = new FormData();
        formData.append('uri', selectedFile, selectedFile.name);
        let config1 = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/public-uploads`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          },
          data: formData
        };
        axios
          .request(config1)
          .then((response) => {
            const profileImage = response.data.filePath;
            console.log('confif1', response.data.filePath);
            if (profileImage) {
              let config2 = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_CORE_HOST}/users/${id}`,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`
                },
                data: {
                  // email: email,
                  // password: password,
                  fullName: fullName ? fullName : '',
                  profileImage: profileImage,
                  phoneNumber: contactNumber,
                  active: active
                }
              };

              axios
                .request(config2)
                .then((response) => {
                  console.log('config', response.data);
                  setSuccessMessage('User successfully Updated');
                  setSuccessOpen(true);
                  navigate('/users');
                })
                .catch((error) => {
                  console.log(error);
                  setErrorMessage('Sorry an error occurred. Please try again');
                  setErrorOpen(true);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage('Sorry an error occurred. Please try again');
            setErrorOpen(true);
          });
      } else {
        let config4 = {
          method: 'patch',
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_CORE_HOST}/users/${id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          },
          data: {
            // email: email,
            // password: password,
            fullName: fullName ? fullName : '',
            phoneNumber: contactNumber,
            active: active
          }
        };

        axios
          .request(config4)
          .then((response) => {
            const userData = response.data;
            console.log(userData);
            setSuccessMessage('User successfully Updated');
            setSuccessOpen(true);
            navigate('/users');
          })
          .catch((error) => {
            console.log(error);
            setErrorMessage('Sorry an error occurred. Please try again');
            setErrorOpen(true);
          });
      }
    }
  });

  const changeHandler = (event) => {
    const fileInput = event.target;
    const selectedFile = fileInput.files[0]; // Get the first selected file

    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const maxSizeInBytes = 4 * 1000 * 1000; // 4 MB in bytes

      if (fileSizeInBytes > maxSizeInBytes) {
        // Display an error message to the user
        // setErrorMessage('Please upload file size less than 2 MB');
        setErrorMessage('Please Upload file less than 4 MB');
        setErrorOpen(true);
        // Clear the file input
        fileInput.value = '';
      } else {
        setSelectedFile(event.target.files[0]);
        setFile(URL.createObjectURL(event.target.files[0]));
      }
    }
  };

  const dialogOpen = () => {
    setOpen(true);
  };
  const dialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Page title */}
      <Grid sx={{ mt: '5%' }}>
        <Typography variant="h3" color="#b71c1c" align="left" mt="-5%">
          Edit User
        </Typography>
      </Grid>
      {/* Breadcrumbs */}
      <Grid sx={{ mt: '1%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={'/users'} style={{ textDecoration: 'none' }}>
            Users
          </Link>
          <Typography style={{ textDecoration: 'none' }}>Edit User</Typography>
        </Breadcrumbs>
      </Grid>
      {/* Form */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container directon="column" sx={{ mt: '1%', backgroundColor: '#FFFF' }}>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Full Name<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Status<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                type="text"
                required
                fullWidth
                placeholder="Full Name"
                name="fullName"
                variant="outlined"
                value={formik.values.fullName}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography>
                Inactive{' '}
                <IsuzuSwitch name="active" value={formik.values.active} checked={formik.values.active} onChange={formik.handleChange} />{' '}
                Active
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Profile Image</Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Contact Number</Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                type="file"
                fullWidth
                placeholder="Thumbnail"
                name="profileImage"
                helperText="Upload  size should be less than 4 MB | Recommended size: 640px * 480px"
                variant="outlined"
                // value={formik.values.thumbnail}
                onChange={changeHandler}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{file && <Avatar onClick={dialogOpen} src={file} variant="square" />}</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                required
                type="text"
                fullWidth
                placeholder="Contact Number"
                name="contactNumber"
                variant="outlined"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          {/* Action Buttons */}
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            {buttonDisabled == false ? (
              <ColorButton type="submit" onClick={() => console.log('# Edit User')}>
                <Typography variant="h4" color="black" align="center">
                  UPDATE
                </Typography>
              </ColorButton>
            ) : (
              <LoadingButton
                loading={true}
                loadingPosition="end"
                variant="contained"
                endIcon={<SaveIcon />}
                style={{ backgroundColor: 'grey' }}
              >
                <Typography variant="h4" color="black" align="center">
                  UPDATE
                </Typography>
              </LoadingButton>
            )}
            &nbsp;
            <BackButton>
              <Link to={'/users'} style={{ color: 'inherit', textDecoration: 'none' }}>
                <Typography variant="h4" color="black" align="center">
                  CANCEL
                </Typography>
              </Link>
            </BackButton>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        open={errorOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={dialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogContent>
          <div>
            <img src={file} style={{ height: '100%', width: '100%' }} alt="Checklist" />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddUser;
