// material-ui
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
// project import
import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';
// import { Typography } from '../../../../../node_modules/@mui/material/index';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import { useEffect } from 'react';
// import axios from 'axios';
// import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDrawer } from 'store/reducers/menu';

import Logo from 'components/Logo/Logo';
import MenuIcon from '@mui/icons-material/Menu';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ open }) => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const matchesLessThan1300px = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    console.log('clicked');
    dispatch(openDrawer({ drawerOpen: true }));
  };
  const handleClickClose = () => {
    console.log('clicked');
    dispatch(openDrawer({ drawerOpen: false }));
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ paddingTop: '1em', width: '80px' }}>{<Logo />}</div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {matchesLessThan1300px ? open ? <MenuIcon onClick={handleClickClose} /> : <MenuIcon onClick={handleClickOpen} /> : null}
        </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', color: 'black', paddingRight: '2em' }}>
        {!matchesXs && <Profile />}
        {matchesXs && <MobileSection />}
      </div>
    </>
  );
};

HeaderContent.propTypes = {
  open: PropTypes.bool
};

export default HeaderContent;
