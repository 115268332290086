import PropTypes from 'prop-types';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import MenuIcon from '@mui/icons-material/Menu';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import { useDispatch } from 'react-redux';
import { openDrawer } from 'store/reducers/menu';

const icon = {
  FormatIndentIncreaseIcon,
  MenuIcon
};

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    dispatch(openDrawer({ drawerOpen: true }));
  };
  const handleClickClose = () => {
    dispatch(openDrawer({ drawerOpen: false }));
  };

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {open ? (
            <icon.MenuIcon sx={{ color: 'white' }} onClick={handleClickClose} />
          ) : (
            <icon.MenuIcon sx={{ color: 'white' }} onClick={handleClickOpen} />
          )}
        </div>
      </Stack>
      {/* <div
        // eslint-disable-next-line react/no-unknown-property
        style={{
          // content: '',
          backgroundColor: '#ffffff',
          width: '50px',
          height: '30px',
          position: 'absolute'
        }}
      /> */}
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
