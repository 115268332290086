import logoImage from '../../assets/images/logo/Isuzu-logo.png';

const Logo = () => {
  return (
    <>
      <img src={logoImage} alt="Logo" style={{ width: '80px' }} />
    </>
  );
};

export default Logo;
