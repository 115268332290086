import { SettingsNotifications } from 'pages/Settings/settingsnotification';
import React from 'react';
// import { Grid } from '../../../node_modules/@mui/material/index';
//   import { Title } from '../../../node_modules/@mui/icons-material/index';

const Settings = () => {
  return (
    <>
      <div style={{ color: 'red' }}>
        <h1>Settings</h1>
      </div>
      <SettingsNotifications />
    </>
  );
};
export default Settings;
