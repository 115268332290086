// assets
import { DashboardOutlined } from '@ant-design/icons';
import GradingIcon from '@mui/icons-material/Grading';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import GTranslateOutlinedIcon from '@mui/icons-material/GTranslateOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import MapIcon from '@mui/icons-material/Map';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WorkIcon from '@mui/icons-material/Work';
import LanguageIcon from '@mui/icons-material/Language';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import FeedIcon from '@mui/icons-material/Feed';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ContactsIcon from '@mui/icons-material/Contacts';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
// icons
const icons = {
  DashboardOutlined,
  GradingIcon,
  HandshakeOutlinedIcon,
  FmdGoodOutlinedIcon,
  PeopleAltOutlinedIcon,
  GTranslateOutlinedIcon,
  SettingsOutlinedIcon,
  NotificationsNoneIcon,
  LanguageIcon,
  ApartmentIcon,
  AccountTreeIcon,
  WorkIcon,
  VideoLabelIcon,
  FeedIcon,
  PostAddIcon,
  EditNoteOutlinedIcon,
  EqualizerIcon,
  RateReviewIcon,
  ContactsIcon,
  HelpCenterOutlinedIcon,
  DeleteOutlinedIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const superAdminDashboard = {
  id: 'group-dashboard',
  // title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'checklist',
      title: 'Checklists',
      type: 'item',
      url: '/checklists',
      icon: icons.GradingIcon,
      breadcrumbs: true
    },
    // Combined to one module Reports/completed
    {
      id: 'reports',
      title: 'Reports',
      type: 'item',
      url: '/reports',
      icon: icons.EqualizerIcon,
      breadcrumbs: true
    },
    // {
    //   id: 'reviews',
    //   title: 'Completed Checklists',
    //   type: 'item',
    //   url: '/reviews',
    //   icon: icons.RateReviewIcon,
    //   breadcrumbs: true
    // },
    {
      id: 'reviewsReports',
      title: 'Completed Checklist',
      type: 'item',
      url: '/reportscompleted',
      icon: icons.RateReviewIcon,
      breadcrumbs: true
    },
    {
      id: 'regions',
      title: 'Regions',
      type: 'item',
      url: '/regions',
      icon: icons.LanguageIcon,
      breadcrumbs: true
    },
    {
      id: 'staff',
      title: 'Staffs',
      type: 'item',
      url: '/staffs',
      icon: icons.HandshakeOutlinedIcon,
      breadcrumbs: true
    },
    {
      id: 'dealers',
      title: 'Dealers',
      type: 'item',
      url: '/dealers',
      icon: icons.ApartmentIcon,
      breadcrumbs: true
    },
    // {
    //   id: 'branches',
    //   title: 'Branches',
    //   type: 'item',
    //   url: '/branches',
    //   icon: icons.AccountTreeIcon,
    //   breadcrumbs: true
    // },
    {
      id: 'departments',
      title: 'Departments',
      type: 'item',
      url: '/departments',
      icon: icons.WorkIcon,
      breadcrumbs: true
    },
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: icons.PeopleAltOutlinedIcon,
      breadcrumbs: true
    },
    {
      id: 'language',
      title: 'Languages',
      type: 'item',
      url: '/languages',
      icon: icons.GTranslateOutlinedIcon,
      breadcrumbs: true
    },
    {
      id: 'Video',
      title: 'Videos',
      type: 'item',
      url: '/videos',
      icon: icons.VideoLabelIcon,
      breadcrumbs: true
    },
    {
      id: 'News',
      title: 'News',
      type: 'item',
      url: '/news',
      icon: icons.FeedIcon,
      breadcrumbs: true
    },
    {
      id: 'Flyer',
      title: 'Promotions',
      type: 'item',
      url: '/promotion',
      icon: icons.PostAddIcon,
      breadcrumbs: true
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/settings',
      icon: icons.SettingsOutlinedIcon,
      breadcrumbs: true
    },
    {
      id: 'Feedbacks',
      title: 'Feedbacks',
      type: 'item',
      url: '/feedbacks',
      icon: icons.EditNoteOutlinedIcon,
      breadcrumbs: true
    },
    {
      id: 'Contact',
      title: 'Contact Details',
      type: 'item',
      url: '/contactDetails',
      icon: icons.ContactsIcon,
      breadcrumbs: true
    },
    {
      id: 'DeleteRequest',
      title: 'Delete Requests',
      type: 'item',
      url: '/deleteRequest',
      icon: icons.DeleteOutlinedIcon,
      breadcrumbs: true
    },
    {
      id: 'User_Manual',
      title: 'User Manual',
      type: 'item',
      url: 'https://isuzu-ds.s3.me-central-1.amazonaws.com/assets/help/Admin%20Portal%20-%20User%20Manual.pdf',
      icon: icons.HelpCenterOutlinedIcon,
      breadcrumbs: true,
      target: '_blank'
    }
  ]
};

export default superAdminDashboard;
