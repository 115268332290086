import React, { useState } from 'react';
import {
  TextField,
  Grid,
  Button,
  Typography,
  Breadcrumbs,
  Snackbar,
  Alert,
  Switch,
  Backdrop,
  CircularProgress
} from '../../../node_modules/@mui/material/index';
import { useFormik } from '../../../node_modules/formik/dist/index';
import { Link, useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import axios from '../../../node_modules/axios/index';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '../../../node_modules/@mui/lab/index';
import SaveIcon from '@mui/icons-material/Save';

const ColorButton = styled(Button)(() => ({
  color: '#000000',
  backgroundColor: 'gray',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: '#ff0000',
    borderRadius: '8px'
  }
}));

const BackButton = styled(Button)(() => ({
  color: '#000000',
  backgroundColor: 'silver',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'gray',
    borderRadius: '8px'
  }
}));

const IsuzuSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#FF0000' : '#FF0000',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

function AddLanguages() {
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorOpen(false);
    setSuccessOpen(false);

    window.location.reload(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      code: '',
      active: true
    },

    onSubmit: async (values) => {
      setButtonDisabled(true);
      const { name, code, active } = values;

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_CORE_HOST}/languages`,
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        data: {
          name: name,
          code: code,
          active: active
        }
      };

      try {
        setLoading(true);

        axios.request(config).then(function (response) {
          const data = response.data;

          console.log(data);

          setLoading(false);
          setSuccessOpen(true);
          setTimeout(() => {
            navigate('/languages');
          }, 2000);
        });
      } catch (err) {
        console.log('Error->', err);
        setLoading(false);
        setErrorOpen(true);
      }
    }
  });

  return (
    <>
      {/* Page title */}
      <Grid sx={{ mt: '5%' }}>
        <Typography variant="h3" color="#b71c1c" align="left" mt="-5%">
          Add Language
        </Typography>
      </Grid>
      {/* Breadcrumbs */}
      <Grid sx={{ mt: '1%' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={'/languages'} style={{ textDecoration: 'none' }}>
            Languages
          </Link>
          <Typography style={{ textDecoration: 'none' }}>Add Language</Typography>
        </Breadcrumbs>
      </Grid>
      {/* Form */}
      <form onSubmit={formik.handleSubmit}>
        <Grid container directon="column" sx={{ mt: '1%', backgroundColor: '#FFFF' }}>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Language Name<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">
                Language Code<sup style={{ color: 'red' }}>*</sup>
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                type="text"
                fullWidth
                placeholder="Language Name"
                name="name"
                required
                variant="outlined"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <TextField
                type="text"
                fullWidth
                placeholder="Language Code"
                value={formik.values.code}
                name="code"
                required
                variant="outlined"
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography variant="h5">Status</Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              &nbsp;
            </Grid>
          </Grid>
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              <Typography>
                Inactive{' '}
                <IsuzuSwitch name="active" checked={formik.values.active} value={formik.values.active} onChange={formik.handleChange} />{' '}
                Active
              </Typography>
            </Grid>
            <Grid xs={1} md={1}>
              &nbsp;
            </Grid>
            <Grid xs={5} md={5} sx={{ paddingRight: '12px', textAlign: 'left' }}>
              &nbsp;
            </Grid>
          </Grid>
          {/* Action Buttons */}
          <Grid container direction="row" xs={12} md={12} spacing={6} sx={{ m: '1rem', alignItems: 'center' }}>
            {buttonDisabled == false ? (
              <ColorButton type="submit" onClick={() => console.log('# Save language')}>
                <Typography variant="h4" color="black" align="center">
                  SAVE
                </Typography>
              </ColorButton>
            ) : (
              <LoadingButton
                loading={true}
                loadingPosition="end"
                variant="contained"
                endIcon={<SaveIcon />}
                style={{ backgroundColor: 'grey' }}
              >
                <Typography variant="h4" color="black" align="center">
                  SAVE
                </Typography>
              </LoadingButton>
            )}
            &nbsp;
            <BackButton type="button" onClick={() => (location.href = '/languages')}>
              <Typography variant="h4" color="black" align="center">
                CANCEL
              </Typography>
            </BackButton>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={errorOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {'Sorry an error occurred. Please try again.'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="success">
          {'Language created successfully'}
        </Alert>
      </Snackbar>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
export default AddLanguages;
