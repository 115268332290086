import { Outlet } from 'react-router-dom';
import '../MinimalLayout/style.css';
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <>
    <div className="containerLogin">
      <Outlet />
    </div>
  </>
);

export default MinimalLayout;
