import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const ForgotPassword = Loadable(lazy(() => import('pages/authentication/ForgotPassword')));
const Login = Loadable(lazy(() => import('pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('pages/authentication/ResetPassword')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/authentication/PrivacyPolicy')));
const TermsAndConditions = Loadable(lazy(() => import('pages/authentication/TermsAndConditions')));

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/',
      element: <Login />
    },
    {
      path: 'login',
      element: <Login />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'login/forgotpassword',
      element: <ForgotPassword />
    },
    {
      path: 'privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: 'terms-and-conditions',
      element: <TermsAndConditions />
    },
    {
      path: 'reset-password',
      element: <ResetPassword />
    }
  ]
};

export default LoginRoutes;
