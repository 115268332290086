import React from 'react';
import { useState, useEffect } from 'react';
import { Box, Grid, Pagination, Skeleton } from '../../../node_modules/@mui/material/index';
import { TableHead, Table, TableRow, TableBody, Typography } from '../../../node_modules/@mui/material/index';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import AddIcon from '@mui/icons-material/Add';
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { Link } from '../../../node_modules/react-router-dom/dist/index';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import Dot from 'components/@extended/Dot';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.black,
    textAlign: `left`,
    lineHeight: '0.25rem',
    paddingLeft: '2%'
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '0px',
    backgroundColor: '#ffffff',
    color: theme.palette.common.black,
    fontSize: 14,
    textAlign: `left`,
    paddingLeft: '2%'
  }
}));

const StyledTableRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    textAlign: `center`,
    backgroundColor: '#C9C9C9',
    paddingLeft: '2%'
  },
  '& td': {
    textAlign: `left`,
    fontSize: 16,
    borderBottom: '1px solid #C9C9C9',
    backgroundColor: '#ffffff',
    paddingLeft: '2%'
  },
  '&:last-child td': {
    textAlign: `left`,
    backgroundColor: '#ffffff',
    paddingLeft: '2%'
  }
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#nnnnnn'),
  backgroundColor: 'white',
  height: '100%',
  '&:hover': {
    color: '#b71c1c'
  }
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '1px solid #FFFFFF',
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '12ch'
      }
    }
  }
}));

const Users = () => {
  const [users, setUsers] = useState([]);
  const accesstoken = localStorage.getItem('ACCESS_TOKEN');
  const [page, setPage] = useState(0);
  const [searchText, setSearch] = useState();
  const [userLength, setUserLength] = useState(-1);

  useEffect(() => {
    let url = `${process.env.REACT_APP_CORE_HOST}/users?$limit=20&$sort[email]=1`;

    if (searchText && searchText.length > 0) {
      url = `${process.env.REACT_APP_CORE_HOST}/users?$sort[email]=1&email[$ilike]=%${searchText}%`;
    }

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${accesstoken}`
      }
    };
    axios
      .request(config)
      .then((response) => {
        const data = response.data;
        // const user = data.detail.user;
        const newData = data.total / 20;
        const modulusData = data.total % 20;
        if (newData > 1) {
          if (modulusData > 0) {
            setPage(Math.trunc(data.total / 20) + 1);
          } else {
            setPage(Math.trunc(data.total / 20));
          }
        } else {
          setPage(Math.trunc(data.total / 20));
        }
        setUsers(data.data);
        const all = data.data;
        setUserLength(all.length);
        console.log(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [accesstoken, searchText]);

  const handleSkip = (e, value) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/users?$limit=20&$skip=${(value - 1) * 20}&$sort[email]=1`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${accesstoken}`
      }
    };
    try {
      axios.request(config).then((response) => {
        const data = response.data;
        const newData = data.total / 20;
        const modulusData = data.total % 20;
        if (newData > 1) {
          if (modulusData > 0) {
            setPage(Math.trunc(data.total / 20) + 1);
          } else {
            setPage(Math.trunc(data.total / 20));
          }
        } else {
          setPage(Math.trunc(data.total / 20));
        }
        setUsers(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Typography sx={{ color: 'red' }} variant="h2">
            Users
          </Typography>
        </Grid>
        <Grid item xs={8} sm={8} md={8} lg={8} sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Search sx={{ borderColor: '#FFFFFF', mr: '10px' }}>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: '#FFFFFF' }} />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                sx={{ color: '#FFFFFF' }}
                inputProps={{ 'aria-label': 'search' }}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    setSearch(event.target.value);
                  }
                }}
              />
            </Search>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4}>
            <Link to={'addusers'} style={{ textDecoration: 'none' }}>
              <Button
                variant="outlined"
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  backgroundColor: '#FFFFFF',
                  color: '#A6A6A6',
                  border: '1px solid #A6A6A6',
                  '&:hover': { border: '1px solid #FF0000', backgroundColor: '#FFFFFF', color: '#FF0000' }
                }}
              >
                <AddIcon />
                Add User
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell style={{ textAlign: 'left' }}>Name</StyledTableCell>
            <StyledTableCell style={{ textAlign: 'left' }}>Email</StyledTableCell>
            <StyledTableCell style={{ textAlign: 'left' }}>Phone</StyledTableCell>
            <StyledTableCell style={{ textAlign: 'left' }}>Role</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {users && users.length > 0 ? (
            users.map((item) => {
              return (
                <StyledTableRow key={item.id}>
                  <StyledTableCell>{item.fullName}</StyledTableCell>
                  <StyledTableCell>{item.email}</StyledTableCell>
                  <StyledTableCell>{item.phoneNumber}</StyledTableCell>
                  <StyledTableCell>{item.role}</StyledTableCell>
                  <StyledTableCell>
                    <div>
                      {item.active ? (
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                          <Dot color="error" />
                        </Box>
                      ) : (
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                          <Dot color="secondary" />
                        </Box>
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell alignItems="center">
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                      <Link to={`/users/updateusers/${item.id}`} style={{ textDecoration: 'none' }} key={'edit-' + item.id}>
                        <ColorButton startIcon={<EditIcon />}></ColorButton>
                      </Link>
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })
          ) : (
            <>
              {userLength == 0 ? (
                <Grid
                  container
                  direction="row"
                  xs={12}
                  md={12}
                  spacing={6}
                  sx={{ m: '1rem', alignItems: 'center' }}
                  style={{ color: '#FFFFFF' }}
                >
                  <div>No users available.</div>
                </Grid>
              ) : (
                <StyledTableRow>
                  <StyledTableCell>
                    <Skeleton variant="text" sx={{ margin: '10px', fontSize: '1rem' }} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Skeleton variant="text" sx={{ margin: '10px', fontSize: '1rem' }} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Skeleton variant="text" sx={{ margin: '10px', fontSize: '1rem' }} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Skeleton variant="text" sx={{ margin: '10px', fontSize: '1rem' }} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Skeleton variant="text" sx={{ margin: '10px', fontSize: '1rem' }} />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Skeleton variant="text" sx={{ margin: '10px', fontSize: '1rem' }} />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>
      {page > 1 ? (
        <Pagination
          sx={{ display: 'flex', justifyContent: 'right', backgroundColor: '#FFFFFF' }}
          count={page}
          onChange={handleSkip}
          variant="outlined"
          shape="rounded"
        />
      ) : (
        ''
      )}
    </>
  );
};
export default Users;
