import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import '../MainLayout/style.css';
// project import
import Drawer from './Drawer';
import Header from './Header';
// import Footer from './Footer/Footer.js';
// import navigation from 'menu-items';
// import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { openDrawer } from 'store/reducers/menu';
import { useLocation } from '../../../node_modules/react-router-dom/dist/index';
// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);

  // Exclude Auth location from outlet
  const location = useLocation();
  const excludedRoutes = ['/', '/login', '/register', '/login/forgotpassword'];
  const shouldExcludeStyling = excludedRoutes.includes(location.pathname);
  // console.log('excluding', shouldExcludeStyling);
  // drawer toggler
  const [open, setOpen] = useState(drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header />
        <Drawer
          PaperProps={{
            elevation: 10
          }}
          open={open}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box
          disableDivider
          sx={{
            width: '100%',
            flexGrow: 1,
            position: 'relative',
            // overflowX: 'hidden'
            marginBottom: '1rem'
          }}
        >
          <div className={shouldExcludeStyling ? 'excludingcontainerstyling' : 'container'}>
            <Outlet />
          </div>
        </Box>
      </Box>
      {/* <Box sx={{ position: 'absolute' }}>
        <Footer />
      </Box> */}
    </>
  );
};

export default MainLayout;
