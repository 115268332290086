import React, { useEffect } from 'react';
import { useState } from 'react';
import { TableHead, Table, TableRow, TableBody, Box, Typography, Snackbar, Alert } from '../../../node_modules/@mui/material/index';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Link, useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import { Grid } from '../../../node_modules/@mui/material/index';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import InputBase from '@mui/material/InputBase';
import { alpha } from '@mui/material/styles';
import Dot from '../../components/@extended/Dot.js';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.black,
    textAlign: `center`,
    lineHeight: '0.25rem'
    //   borderBottomWidth: '1px',
    //   borderBottomStyle: 'solid',
    //   borderColor: 'black',
    // borderRadius: "10px 0 0 10px'"
  },
  [`&.${tableCellClasses.body}`]: {
    padding: '0px',
    backgroundColor: '#ffffff',
    color: theme.palette.common.black,
    fontSize: 14,
    textAlign: `center`
  }
}));

const StyledTableRow = styled(TableRow)({
  '&:last-child td, &:last-child th': {
    textAlign: `center`,
    // borderBottom: '1px solid black',
    backgroundColor: '#C9C9C9'
    // borderTopRightRadius: '10px 5px 1'
  },
  '& td': {
    textAlign: `center`,
    fontSize: 16,
    // border: '1px solid #C9C9C9',
    borderBottom: '1px solid #C9C9C9',
    backgroundColor: '#ffffff'
  },
  '&:last-child td': {
    textAlign: `center`,
    //   borderBottom: '1px solid black',
    backgroundColor: '#ffffff'
    // borderBottomRightRadius: '10px'
  }
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '12ch'
      }
    }
  }
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#nnnnnn'),
  backgroundColor: 'white',
  height: '100%',
  '&:hover': {
    color: '#b71c1c'
  }
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  border: '2px solid red',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto'
  }
}));

const Countries = () => {
  const [countries, setCountries] = useState();
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  const navigate = useNavigate();
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [regions, setregions] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorOpen(false);
    setSuccessOpen(false);
    window.location.reload(false);
  };

  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    }
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/country`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    try {
      axios.request(config).then((response) => {
        const data = response.data;
        setCountries(data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }, [accessToken, navigate]);

  useEffect(() => {
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/regions`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    try {
      axios.request(config).then(function (response) {
        const data = response.data;
        setregions(data.data);
      });
    } catch (err) {
      console.log('err->', err);
    }
  }, [accessToken]);

  const handleDelete = async (id) => {
    const config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_CORE_HOST}/country/${id}`,
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    };
    try {
      await axios(config).then(function (response) {
        const data = response.data;

        console.log(data);
        setCountries(countries.filter((prev) => prev.id != id));
        setSuccessOpen(true);
      });
    } catch (err) {
      console.log('deleted error', err);
      setErrorOpen(true);
    }
  };

  return (
    <>
      <Grid container xs={12} sm={12} md={12} lg={12} sx={{ display: 'flex', flexDirection: 'row', marginBottom: '1rem' }}>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <Typography sx={{ color: 'red' }} variant="h2">
            Countries
          </Typography>
        </Grid>
        <Grid item xs={7} sm={7} md={7} lg={7} sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'flex-end' }}>
          <Grid item xs={3} sx={{ marginRight: '20px' }}>
            <Search sx={{ borderRadius: 6, borderColor: '#A6A6A6', mr: '1%' }}>
              <SearchIconWrapper>
                <SearchIcon sx={{ color: '#A6A6A6' }} />
              </SearchIconWrapper>
              <StyledInputBase placeholder="Search…" sx={{ color: '#000000' }} inputProps={{ 'aria-label': 'search' }} />
            </Search>
          </Grid>
          <Grid item xs={3}>
            <Link to={'addcountry'} style={{ textDecoration: 'none' }}>
              <Button
                variant="outlined"
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  color: '#A6A6A6',
                  border: '2px solid #A6A6A6',
                  '&:hover': { textDecoration: 'none', border: '2px solid #A6A6A6' }
                }}
              >
                <AddIcon />
                Add Country
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Country Name</StyledTableCell>
            <StyledTableCell>Code</StyledTableCell>
            <StyledTableCell>Flag</StyledTableCell>
            <StyledTableCell>Region</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
            <StyledTableCell>Created On</StyledTableCell>
            <StyledTableCell>Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {countries &&
            countries.map((item) => {
              return (
                <StyledTableRow key={item.id}>
                  <StyledTableCell>{item.name}</StyledTableCell>
                  <StyledTableCell>{item.code}</StyledTableCell>
                  <StyledTableCell>{item.flagUrl}</StyledTableCell>
                  <StyledTableCell>
                    {regions &&
                      regions.map((newitem) => {
                        if (newitem.id == item.region_id) {
                          return newitem.name;
                        }
                      })}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div>
                      {item.active ? (
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                          <Dot color="error" />
                        </Box>
                      ) : (
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                          <Dot color="secondary" />
                        </Box>
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>{item.createdAt.slice(0, 10)}</StyledTableCell>
                  <StyledTableCell>
                    <Link to={`updatecountries/${item.id}`} style={{ textDecoration: 'none' }} key={'edit-' + item.id}>
                      <ColorButton startIcon={<EditIcon />}></ColorButton>
                    </Link>
                    |
                    <ColorButton
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        handleDelete(parseInt(item.id));
                      }}
                    ></ColorButton>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
      <Snackbar
        open={errorOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="error">
          {'Sorry an error occurred. Please try again.'}
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Alert onClose={handleClose} severity="success">
          {'Country deleted successfully'}
        </Alert>
      </Snackbar>
    </>
  );
};
export default Countries;
